import React, { useEffect, useState } from 'react'

import { createStripeClient, injectStripeScript, isStripeInjected } from '@elo-kit/utils/stripe.utils'

import { useShopStores } from 'shop/hooks/use-store'

import { ClickSubmitData } from 'shop/types/stripe'

import { DEFAULT_STRIPE_AMOUNT, SETUP_FUTURE_USAGE, STRIPE_ELEMENTS_MODE } from 'constants/stripe.constants'
import { PAYMENT_PROVIDERS } from 'constants/paymentSettingShared.constants'

interface Props {
  googleProvider: string
  googlePubKey: string
  handleClickSubmit: (data: ClickSubmitData) => void
  handleClickClose: () => void
  payOptions: {
    amount: number
    currency: string
  }
  show: boolean
}

const GOOGLE_PAY_EXPRESS_ELEMENT_ID = 'express-google-checkout-element'

export const GooglePayExpressElement: React.FC<Props> = (props) => {
  const { googleProvider, googlePubKey, handleClickSubmit, handleClickClose, payOptions, show } = props

  const [stripeElements, setStripeElements] = useState(null)

  const { paymentStore, purchaseMethodsStore, sellerStore } = useShopStores()

  useEffect(() => {
    if (isStripeInjected()) {
      initGoogleExpress()
    } else {
      injectStripeScript(initGoogleExpress)
    }
  }, [])

  useEffect(() => {
    if (stripeElements) {
      stripeElements.update({ amount: payOptions.amount || DEFAULT_STRIPE_AMOUNT })
    }
  }, [payOptions.amount])

  const initGoogleExpress = () => {
    const options = {
      locale: I18n.locale,
      mode: STRIPE_ELEMENTS_MODE.payment,
      amount: payOptions.amount || DEFAULT_STRIPE_AMOUNT,
      currency: payOptions.currency,
      setupFutureUsage: SETUP_FUTURE_USAGE.offSession,
      ...(googleProvider === PAYMENT_PROVIDERS.elopageConnect
        ? { onBehalfOf: sellerStore.item.elopageConnectAccountId }
        : {}),
    }

    if (googlePubKey) {
      const stripeClient = createStripeClient(googlePubKey)
      const elements = stripeClient.elements(options)

      setStripeElements(elements)

      const expressCheckoutElement = elements.create('expressCheckout', {
        paymentMethods: {
          googlePay: 'always',
          applePay: 'never',
        },
      })

      if (expressCheckoutElement) {
        expressCheckoutElement.mount(`#${GOOGLE_PAY_EXPRESS_ELEMENT_ID}`)
        expressCheckoutElement.on('ready', ({ availablePaymentMethods }) => {
          paymentStore.setGoogleAvailability(availablePaymentMethods || {})
          purchaseMethodsStore.setGoogleAvailability(availablePaymentMethods || {})
        })

        expressCheckoutElement.on('click', (event) => {
          const showPopup = () => event.resolve()
          handleClickSubmit({
            stripeClient: stripeClient,
            elements: elements,
            expressElement: expressCheckoutElement,
            showPopup: showPopup,
          })
        })

        expressCheckoutElement.on('cancel', handleClickClose)
      }
    }
  }

  return <div id={GOOGLE_PAY_EXPRESS_ELEMENT_ID} style={{ display: show ? 'block' : 'none' }} />
}
