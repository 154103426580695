export const STRIPE_ELEMENTS_MODE = {
  payment: 'payment',
  setup: 'setup',
  subscription: 'subscription',
} as const

export const SETUP_FUTURE_USAGE = {
  offSession: 'off_session',
  onSession: 'on_session',
} as const

export const DEFAULT_STRIPE_AMOUNT = 100
