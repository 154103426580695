import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
/* eslint-disable */
import { ReactPayPalScriptOptions } from '@paypal/react-paypal-js/dist/types/types/scriptProviderTypes'
import { PayPalMessagesComponentProps } from '@paypal/react-paypal-js'
import {
  DISPATCH_ACTION,
  PayPalButtons,
  PayPalButtonsComponentProps,
  PayPalMessages,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js'
import classNames from 'classnames'

/*eslint-enable */
import { PaymentStore } from 'shop/stores/payment.store'
import { CreateOrderResponse } from 'shop/api/orders.api'
import { Nullable } from 'types/helpers'
import { LoadingMask } from '@elo-kit/components'
import { isProduction } from 'utils/env.utils'

import './paypal-controls.scss'

export type PaypalMessageCurrencies = 'USD' | 'GBP' | 'EUR'

interface paypalMessageProps {
  currency: PaypalMessageCurrencies
  amount: number
  payerCountry: string
  ignorePayerCounty?: boolean
  isPreview?: boolean
  payPalProps?: PayPalMessagesComponentProps
}

interface paypalButtonsProps {
  paymentStore: PaymentStore
  paypalScriptOptions: ReactPayPalScriptOptions
  submit: () => Promise<CreateOrderResponse>
  children: React.ReactElement
  style?: {
    color?: 'gold' | 'blue' | 'silver' | 'white' | 'black'
    disableMaxWidth?: boolean
    height?: number
    label?: 'paypal' | 'checkout' | 'buynow' | 'pay' | 'installment' | 'subscribe' | 'donate'
    layout?: 'vertical' | 'horizontal'
    shape?: 'rect' | 'pill'
    tagline?: boolean
  }
  buyButtonDisabled?: boolean
  isPreview?: {
    isCabinetPreview?: boolean
    isPreview?: boolean
  }
}

export const PayPalBuyButtons = ({
  paypalScriptOptions,
  submit,
  paymentStore,
  children,
  style,
  buyButtonDisabled,
  isPreview,
}: paypalButtonsProps) => {
  const [{ options, isPending, isRejected }, dispatch] = usePayPalScriptReducer()
  let orderData: Nullable<CreateOrderResponse> = null

  useEffect(() => {
    dispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: {
        ...options,
        ...paypalScriptOptions,
      },
    })
  }, [paymentStore?.store?.activePlan?.id, ...(!isProduction() ? [paypalScriptOptions.buyerCountry] : [])])

  const buttonProps: PayPalButtonsComponentProps = {
    createOrder: () =>
      submit().then((order) => {
        if (order?.success) {
          orderData = order
          if (order.paypalPaylaterAttributes && order.paypalPaylaterAttributes.orderId) {
            return order.paypalPaylaterAttributes.orderId
          }

          if (order.redirectLink) {
            paymentStore.handleRedirect(order.redirectLink)
          }
        }
        return ''
      }),
    onApprove: (data, actions) => {
      actions.redirect(orderData.paypalPaylaterAttributes?.returnUrl)
      return new Promise((resolve) => resolve())
    },
    onCancel: (data, actions) => {
      //@ts-ignore
      actions.redirect(orderData.paypalPaylaterAttributes?.cancelUrl)
    },
    onClick: (data, { reject }) => {
      if (!paymentStore.isValidForSubmit) {
        paymentStore.validateForm()
        setTimeout(paymentStore.highlightInvalidFields, 500)
        return reject()
      }
    },
    onError: () => {
      paymentStore.hideLoading()
    },
    style,
  }

  if (isRejected) {
    return <>{children}</>
  }

  const buttonsClasses = classNames('paypal-controls__buttons-container', {
    'paypal-controls__buttons-container--preview': isPreview?.isCabinetPreview,
  })

  return isPending ? (
    <LoadingMask />
  ) : (
    <PayPalButtons
      className={buttonsClasses}
      disabled={
        buyButtonDisabled || paymentStore?.buyBtnDisabled || isPreview?.isPreview || isPreview?.isCabinetPreview
      }
      {...buttonProps}
    />
  )
}

export const PaypalPayLaterMessages = observer(function PaypalPayLaterMessages({
  currency,
  amount,
  payerCountry,
  ignorePayerCounty,
  isPreview,
  payPalProps = {},
}: paypalMessageProps) {
  const [data, dispatch] = usePayPalScriptReducer()
  const [meta, setMeta] = useState<any>({})
  const style = meta?.offerCountry === payerCountry || ignorePayerCounty ? { display: 'initial' } : { display: 'none' }

  if (data.isRejected) {
    return <></>
  }

  useEffect(() => {
    dispatch({
      type: DISPATCH_ACTION.RESET_OPTIONS,
      value: {
        ...data.options,
      },
    })
  }, [])

  const messageClasses = classNames('paypal-controls__message-container', {
    'paypal-controls__message-container--preview': isPreview,
  })

  return (
    <div style={style}>
      <PayPalMessages
        className={messageClasses}
        currency={currency}
        forceReRender={[amount, currency]}
        onRender={(data) => setMeta(data.meta || {})}
        amount={amount}
        style={{
          text: {
            size: 12,
          },
        }}
        {...payPalProps}
      />
    </div>
  )
})
