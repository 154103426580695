import { getCookies, setCookies, userSessionCookieKey } from 'libs/common/cookies'
import { apiClient } from 'utils/requests.utils'
import { getSearchParams } from 'utils/queryString.utils'

import {
  createLegacyTrackingUserEventsApi,
  createTrackingUserEventsApi,
  LegacyTrackingUserEventsApi,
  TrackingUserEventsApi,
} from 'shop/api/trackingUserEvents.api'
import { computed } from 'mobx'
import { ShopRootStore } from 'shop/stores/shopRoot.store'
import { flattenObject } from 'shop/utils/transormation.utils'
import { isShopPreview } from 'shop/utils/preview.utils'

type PageType = 'shop' | 'shop_custom_page' | 'shop_product' | 'shop_checkout'

type EventType =
  | 'page_view'
  | 'pricing_plan_change'
  | 'payer_type_change'
  | 'vat_id_check'
  | 'gift_mode_change'
  | 'payment_method_change'
  | 'coupon_view_change'
  | 'coupon_input_blur'
  | 'coupon_success_submit'
  | 'coupon_error_submit'
  | 'extend_checkout_summary_click'
  | 'buy_button_click'
  | 'buy_button_success'
  | 'buy_button_error'
  | 'klarna_selected_checkout'
  | 'klarna_selected_manage_page'
  | 'express_button_click'
  | 'express_button_success'
  | 'express_button_error'

type EventDetails = {
  sellerId: string
  userSessionId: string
  userId?: string
  userAgent: string
  pageUrl: string
  pageReferer: string
}

export type TrackingUserEvent = {
  pageType: PageType
  eventType: EventType
  eventDetails: EventDetails
  eventPayload?: Record<string, unknown>
  pageDetails?: Record<string, unknown>
}

export class TrackingUserEventsStore {
  storeName = 'TrackingUserEventsStore'
  api: TrackingUserEventsApi
  legacyApi: LegacyTrackingUserEventsApi

  constructor(private root: ShopRootStore) {
    this.api = createTrackingUserEventsApi(root?.apiClient ?? apiClient)
    this.legacyApi = createLegacyTrackingUserEventsApi(root?.apiClient ?? apiClient)
  }

  track = ({
    pageType,
    eventType,
    payload = {},
    withExperiment = false,
    withExperimentDetails = false,
  }: {
    pageType: PageType
    eventType: EventType
    payload?: Record<string, unknown>
    withExperiment?: boolean
    withExperimentDetails?: boolean
  }) => {
    if (!this.isEligibleForTracking) {
      return
    }

    const experimentDetails = withExperimentDetails ? this.getAllActiveExperimentsDetails : {}

    this.api.track({
      pageType,
      eventType,
      eventPayload: {
        experiments: experimentDetails,
        ...payload,
      },
      eventDetails: this.getEventDetails,
      pageDetails: this.getEventDetailsByPageType(pageType),
    })

    if (withExperiment) {
      this.trackExperiment(pageType, eventType, {
        ...payload,
        ...this.getEventDetails,
        ...this.getEventDetailsByPageType(pageType),
      })
    }
  }

  trackLegacy = (data: { actionType: string; sellerId: number; productId?: number }) => {
    if (isShopPreview(getSearchParams())) {
      return
    }

    this.legacyApi.track({ ...data, clickerToken: this.clickerToken })
  }

  private trackExperiment = (pageType: PageType, eventType: EventType, payload?: Record<string, unknown>) => {
    this.root.experimentsStore.logEvent(eventType, pageType, flattenObject(payload))
  }

  private get isEligibleForTracking() {
    return !isShopPreview(getSearchParams())
  }

  @computed private get getEventDetails() {
    return {
      sellerId: this.sellerId,
      userId: this.userId,
      userSessionId: this.userSessionId,
      userAgent: this.userAgent,
      userAgentType: this.userAgentType,
      pageUrl: this.pageUrl,
      pageReferer: this.pageReferer,
    }
  }

  private getEventDetailsByPageType(pageType: PageType) {
    switch (pageType) {
      case 'shop_checkout': {
        return this.checkoutPageDetails
      }
      case 'shop_product': {
        return this.productPageDetails
      }
      default: {
        return {}
      }
    }
  }

  @computed private get sellerId(): string {
    return this.root.sellerStore.sellerId.toString()
  }

  @computed private get userId(): string {
    return this.root.userStore.item?.id?.toString()
  }

  @computed private get productId(): string {
    return this.root.productsStore.product?.id?.toString()
  }

  @computed private get productSlug(): string {
    return this.root.productsStore.product?.slug
  }

  @computed private get userSessionId(): string {
    return getCookies(userSessionCookieKey)
  }

  @computed private get checkoutPageDetails() {
    return {
      product: {
        type: this.root.productsStore.product.form,
        free: this.root.paymentStore.product.free,
        id: this.productId,
        slug: this.productSlug,
      },
      pricingPlans: this.pricingPlans,
      summary: {
        totalPrice: this.root.paymentStore.totalPrice(),
      },
      payerForm: this.root.themeStore.ppTemplate.buyerInformation[this.root.paymentStore.payerFromType],
      ...this.buyRecoveryDetails,
    }
  }

  @computed private get productPageDetails() {
    return {
      product: {
        id: this.productId,
        slug: this.productSlug,
      },
    }
  }

  @computed private get pricingPlans() {
    const pricingPlans = this.root.paymentStore.getProductPlansToShow()
    const selectedPricingPlanId = this.root.paymentStore.store.product.planId
    const selectedPricingPlanIndex = pricingPlans.findIndex((plan) => plan.id === selectedPricingPlanId)
    const selectedPricingPlan = pricingPlans[selectedPricingPlanIndex]

    return {
      pricingPlans: pricingPlans.map(({ form, id, prefs }) => ({
        type: form,
        id,
        price: prefs.price || prefs.firstAmount || 0,
      })),
      selectedPricingPlanId,
      selectedPricingPlanIndex: selectedPricingPlanIndex + 1,
      selectedPricingPlanType: selectedPricingPlan?.form,
      selectedPricingPlanPrice: selectedPricingPlan?.prefs?.price || selectedPricingPlan?.prefs?.firstAmount || 0,
      pricingPlansCount: pricingPlans.length,
    }
  }

  @computed private get userAgent(): string {
    return this.root.experimentsStore.userDetails.userAgent
  }

  @computed private get userAgentType(): string {
    return this.root.experimentsStore.userDetails.userAgentType as string
  }

  @computed private get pageUrl(): string {
    return window.location.href
  }

  @computed private get pageReferer(): string {
    return document.referrer
  }

  private get clickerToken() {
    const token = this.generateClickerToken()

    return getCookies('clicker_token') || (setCookies('clicker_token', token) && token)
  }

  private get buyRecoveryDetails() {
    const { recovery_order_id } = getSearchParams()

    if (!recovery_order_id) {
      return {}
    }

    return {
      buyRecovery: {
        orderId: recovery_order_id,
      },
    }
  }

  private generateClickerToken() {
    return Math.random().toString(36).slice(2)
  }

  private get getAllActiveExperimentsDetails() {
    return this.root.experimentsStore.allActiveExperiments
  }
}
