import { makeObservable, action, computed, observable } from 'mobx'

import { PAYMENT_FORMS, PAYMENT_PROVIDERS, CARD_NUMBER } from 'constants/paymentSettingShared.constants'
import { DEFAULT_CURRENCY } from 'constants/currencies.constants'
import { VAT_TYPE } from 'constants/ordersShared.constants'
import { CURRENT_TIMEZONE } from '@elo-kit/constants/dateTime.constants'
import { TRANSFER_PROVIDERS_BASE } from 'constants/transfers.constants'

import { apiClient, getMP3dsBrowserMetaData } from 'utils/requests.utils'
import {
  handleP24Transaction,
  handleIdealTransaction,
  handleSofortTransaction,
  handleStripeCardTransaction,
  handleTransactionResponse,
  handleSepaError,
  handleStripeDigitalTransaction,
  convertPriceForStripe,
} from '@elo-kit/utils/stripe.utils'
import { get } from 'utils/lodash.utils'
import { getSellerLink } from 'utils/helpersShared.utils'
import { createFingerPrint } from 'utils/fingerprint.utils'

import { ClickSubmitData, StripeElementsOptionsMode } from 'shop/types/stripe'

import SharedStore from 'shared/stores/shared.store'
import { ShopRootStore } from 'shop/stores/shopRoot.store'

import { DealsApi, createDealsApi, Deal } from '../api/deals.api'

export class DealsStore extends SharedStore<Deal> {
  storeName = 'DealsStore'
  root: ShopRootStore
  declare childApi: DealsApi
  @observable paramsForSubmit = {
    transfer: {},
  }
  @observable coupon = {}
  @observable terms = {}
  @observable isValidForSubmit = true

  @computed get isPayAllowed() {
    const { selectedPayMethod } = this.item
    const { cardProvider } = this.root.sellerStore.item

    if (
      selectedPayMethod === PAYMENT_FORMS.card &&
      (cardProvider === PAYMENT_PROVIDERS.stripe || cardProvider === PAYMENT_PROVIDERS.elopageConnect)
    ) {
      return this.root.purchaseMethodsStore.stripeCardValid
    }

    return true
  }

  @computed get paymethodsData(): { transfer: { creditCard: string | { num: string }; form: string; iban: string } } {
    const { creditCard: paymethodCard, form, iban } = this.root.purchaseMethodsStore.paymethods || {}
    const { moneyHolder, id } = this.root.ordersStore.item
    const {
      item: { cardProvider },
    } = this.root.sellerStore
    const usedMainProvider = id ? moneyHolder?.cardProvider || cardProvider : cardProvider

    const creditCard = usedMainProvider === PAYMENT_PROVIDERS.lemonWay ? { num: CARD_NUMBER } : paymethodCard

    return {
      transfer: {
        creditCard,
        form,
        iban,
      },
    }
  }

  getSepaStripeElementPayload = (): StripeElementsOptionsMode => {
    const { sepaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    return {
      mode: 'setup',
      paymentMethodCreation: 'manual',
      paymentMethodTypes: ['sepa_debit'],
      currency: this.getCurrency() || DEFAULT_CURRENCY,
      setupFutureUsage: 'off_session',
      ...(sepaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }
  }

  getKlarnaStripeElementPayload = (): StripeElementsOptionsMode => {
    const { klarnaProvider, elopageConnectAccountId } = this.root.sellerStore.item

    return {
      mode: 'payment',
      paymentMethodTypes: [PAYMENT_FORMS.klarna],
      currency: this.getCurrency() || DEFAULT_CURRENCY,
      amount: convertPriceForStripe(this.totalPrice()),
      ...(klarnaProvider === PAYMENT_PROVIDERS.elopageConnect ? { onBehalfOf: elopageConnectAccountId } : {}),
    }
  }

  @action setTerms = (terms?: any) => (this.terms = terms)

  @computed get prepareSellablesForSubmit() {
    const { product, pricingPlan } = this.root.ordersStore.item

    if (this.root.ordersStore.item.invoices[0]?.token) {
      return []
    } else {
      return [
        {
          productId: product.id,
          sellableId: product.id,
          sellableType: 'Product',
          pricingPlanId: pricingPlan.id,
          priceCount: product.count,
          sellableItemsAttributes: [
            {
              productId: product.id,
              ticketsCount: product.count,
              ticketAttendeesAttributes: [],
            },
          ],
        },
      ]
    }
  }

  @computed get isDigitalPayment() {
    const { paymethods: { form } = {} } = this.root.purchaseMethodsStore
    return form === PAYMENT_FORMS.applePay || form === PAYMENT_FORMS.googlePay
  }

  totalPrice = (orderRateIndex = 0) => {
    const { rates = [] } = this.root.ordersStore.item || {}
    const { orderRatePrices = [] } = rates[orderRateIndex] || {}

    const total = orderRatePrices.reduce(
      (result, { data }) => {
        const {
          cfgs: { mustPayVat },
          discount,
          fees,
          rate,
        } = data || {}
        const priceKey = mustPayVat ? VAT_TYPE.gross : VAT_TYPE.net

        const getSum = (currentValue, value) =>
          value ? Number((currentValue + value[priceKey] * value.count).toFixed(2)) : currentValue

        const rateSum = getSum(result.rateSum, rate)
        const feesSum = getSum(result.feesSum, fees)
        const discountSum = getSum(result.discountSum, discount)

        return {
          rateSum,
          feesSum,
          discountSum,
        }
      },
      {
        rateSum: 0,
        feesSum: 0,
        discountSum: 0,
      }
    )

    return total.rateSum + total.feesSum + total.discountSum
  }

  getCurrency = () => this.root.currenciesStore.getKey(this.item.pricingPlan.currencyId)

  handleCancellationTerm = async () => {
    const { formType } = this.item.payerData || {}
    const { cancellationTermId, b2bCancellationTermId } = this.root.productsStore.product
    const {
      item: { username },
    } = this.root.sellerStore
    const { item, fetchItem } = this.root.cancellationTermsStore
    const { includeType } = this.root.themeStore.ppTemplate.buyerInformation

    const hasB2BCancellationTerm = b2bCancellationTermId && b2bCancellationTermId !== item.id
    if (hasB2BCancellationTerm && (formType === 'business' || includeType.private === 'off')) {
      await fetchItem(b2bCancellationTermId, { username })
    } else if (cancellationTermId && cancellationTermId !== item.id) {
      await fetchItem(cancellationTermId, { username })
    }
  }

  handleRedirect = (redirectLink) => {
    if (!redirectLink) {
      window.location.href = getSellerLink(
        this.root.sellerStore.item,
        `/payment/${this.root.ordersStore.item.token}?funnel_on=true`
      )
      return
    }
    window.location.href = redirectLink
  }

  hideLoading = () => {}

  @action payDeal = async (submitData?: ClickSubmitData) => {
    const { selectedPayMethod, pricingPlan, token } = this.item
    const { cardProvider, sofortProvider, stripeSofortSepa, elopageConnectSofortSepa } = this.root.sellerStore.item
    const { prefs } = pricingPlan || {}

    const isDigitalPayment =
      selectedPayMethod === PAYMENT_FORMS.applePay || selectedPayMethod === PAYMENT_FORMS.googlePay
    const showDigitalPaymentModal =
      isDigitalPayment && submitData?.expressElement && !prefs?.testPeriod && !prefs?.customStartDay

    if (showDigitalPaymentModal) {
      submitData.showPopup()
    }

    const isCard = selectedPayMethod === PAYMENT_FORMS.card
    const isMangoPay = cardProvider === PAYMENT_PROVIDERS.mangoPay
    const hasDifferentBillingAddress = !!this.item?.ownerData?.userProfileAttributes
    const { ownerData } = this.item
    const formType = hasDifferentBillingAddress ? 'business' : 'private'

    const paramsForSubmit = {
      id: this.root.ordersStore.item.token,
      orderId: this.root.ordersStore.item.id,
      cancelTerms: this.terms,
      invoice: this.root.ordersStore.item.invoices[0]?.token || null,
      sellables: this.prepareSellablesForSubmit,
      deal: token,
      payer: {
        formType: formType,
        formTypeForVat: formType,
        userProfileAttributes: {
          countryCode: this.item.payerData.userProfileAttributes.countryCode,
          phone: this.item.payerData.userProfileAttributes.phone,
          company: this.item.payerData.userProfileAttributes.company,
          city: this.item.payerData.userProfileAttributes.city,
          street: this.item.payerData.userProfileAttributes.street,
          streetNumber: this.item.payerData.userProfileAttributes.streetNumber,
          zip: this.item.payerData.userProfileAttributes.zip,
          vatNo: this.item.payerData.userProfileAttributes.vatNo,
          firstName: (this.item.payerData.userProfileAttributes.firstName || '').trim(),
          lastName: (this.item.payerData.userProfileAttributes.lastName || '').trim(),
          additionalAddress: this.item.payerData.userProfileAttributes.additionalAddress,
        },
        userAttributes: {
          timeZoneName: CURRENT_TIMEZONE,
          email: this.item.payerData.userAttributes.email,
          emailConfirmation: this.item.payerData.userAttributes.emailConfirmation,
        },
      },
      owner: hasDifferentBillingAddress
        ? {
            userProfileAttributes: {
              countryCode: ownerData?.userProfileAttributes?.countryCode,
              phone: ownerData?.userProfileAttributes?.phone,
              company: ownerData?.userProfileAttributes?.company,
              city: ownerData?.userProfileAttributes?.city,
              street: ownerData?.userProfileAttributes?.street,
              streetNumber: ownerData?.userProfileAttributes?.streetNumber,
              zip: ownerData?.userProfileAttributes?.zip,
              vatNo: ownerData?.userProfileAttributes?.vatNo,
              firstName: ownerData?.userProfileAttributes?.firstName,
              lastName: ownerData?.userProfileAttributes?.lastName,
              additionalAddress: ownerData?.userProfileAttributes?.additionalAddress,
            },
            userAttributes: {
              timeZoneName: CURRENT_TIMEZONE,
              email: ownerData?.userAttributes?.email,
            },
          }
        : null,
      ...this.paramsForSubmit,
      ...this.paymethodsData,
    }

    if (this.root.purchaseMethodsStore.fraudSessionIdentifier) {
      const paypalFraudData = {
        paypalFraudToken: this.root.purchaseMethodsStore.fraudSessionIdentifier,
      }

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...paypalFraudData,
      }
    }

    if (isCard && isMangoPay) {
      const mp3dsBrowserMetaData = getMP3dsBrowserMetaData()

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...mp3dsBrowserMetaData,
      }
    }

    if (selectedPayMethod === PAYMENT_FORMS.sepa && this.root.purchaseMethodsStore.stripeElements) {
      const { error: submitError } = await this.root.purchaseMethodsStore.stripeElements.submit()
      if (submitError) {
        handleSepaError(submitError)
        return
      }

      const { error, confirmationToken } = await this.root.purchaseMethodsStore.stripeClient.createConfirmationToken({
        elements: this.root.purchaseMethodsStore.stripeElements,
        params: {
          payment_method_data: {
            billing_details: {
              name: `${get(this.item, 'payerData.userProfileAttributes.firstName', '')} ${get(
                this.item,
                'payerData.userProfileAttributes.lastName',
                ''
              )}`,
              email: this.item.payerData.userAttributes.email,
              phone: this.item.payerData?.userProfileAttributes.phone || '',
              address: {
                city: this.item.payerData?.userProfileAttributes.city,
                country: this.item.payerData?.userProfileAttributes.countryCode,
                postal_code: this.item.payerData?.userProfileAttributes.zip,
                state: this.item.payerData?.userProfileAttributes.state || '',
                line1: this.item.payerData?.userProfileAttributes.street,
                line2: this.item.payerData?.userProfileAttributes.streetNumber || '',
              },
            },
          },
        },
      })

      if (error) {
        handleSepaError(error)
        return
      }

      const token = { confirmationToken: confirmationToken.id }

      paramsForSubmit.transfer = {
        ...paramsForSubmit.transfer,
        ...token,
      }
    }

    this.toggleLoading(true)
    const orderSessionId = createFingerPrint()
    const resp = await this.root.ordersStore.childApi.createOrder(this.root.sellerStore.item.username, {
      ...paramsForSubmit,
      orderSessionId,
    })
    const { clientSecret, redirectLink, success } = resp
    if (resp && success) {
      const isP24 = selectedPayMethod === PAYMENT_FORMS.p24
      const isIdeal = selectedPayMethod === PAYMENT_FORMS.ideal
      const isStripeSofort =
        selectedPayMethod === PAYMENT_FORMS.sofort &&
        (sofortProvider === PAYMENT_PROVIDERS.stripe || sofortProvider === PAYMENT_PROVIDERS.elopageConnect)

      if (
        selectedPayMethod === PAYMENT_FORMS.paypal &&
        this.root.sellerStore.item.paypalProvider === TRANSFER_PROVIDERS_BASE.paypalRest &&
        this.root.sellerStore.item.paypalRestV2
      ) {
        return resp
      }

      if (
        (isP24 || isCard || isStripeSofort || isDigitalPayment || isIdeal) &&
        (this.root.purchaseMethodsStore?.stripeClient || submitData?.stripeClient) &&
        clientSecret
      ) {
        const fullName = `${get(this.item, 'payerData.userProfileAttributes.firstName', '')} ${get(
          this.item,
          'payerData.userProfileAttributes.lastName',
          ''
        )}`
        const email = get(this.item, 'payerData.userAttributes.email', '')
        const countryCode = get(this.item, 'payerData.userProfileAttributes.countryCode', '')

        if (isP24) {
          handleP24Transaction(
            this.root.purchaseMethodsStore.stripeClient,
            this.root.purchaseMethodsStore.stripeP24,
            redirectLink,
            clientSecret,
            {
              fullName,
              email,
              tosShowAndAccepted: false,
            }
          ).then((result) => {
            const { paymentIntent } = result
            if (paymentIntent) {
              this.handleRedirect(redirectLink)
            }
          })
        }

        if (isIdeal) {
          handleIdealTransaction(
            this.root.purchaseMethodsStore.stripeClient,
            this.root.purchaseMethodsStore.stripeIdeal,
            redirectLink,
            clientSecret,
            {
              fullName,
              email,
            }
          ).then((result) => {
            const { paymentIntent } = result

            if (paymentIntent) {
              this.handleRedirect(redirectLink)
            }
          })
        }

        if (isStripeSofort) {
          const billingDetails =
            prefs?.sofortSepa && (stripeSofortSepa || elopageConnectSofortSepa)
              ? {
                  billing_details: {
                    name: fullName,
                    email,
                  },
                }
              : {}
          handleSofortTransaction(this.root.purchaseMethodsStore.stripeClient, redirectLink, clientSecret, {
            countryCode,
            billingDetails,
          })
        }

        if (isCard) {
          this.toggleLoading(false)
          this.root.purchaseMethodsStore.stripeAuthentificationLoadingToggle(true)
          handleStripeCardTransaction(
            this.root.purchaseMethodsStore.stripeClient,
            { stripeCard: this.root.purchaseMethodsStore.stripeCard },
            clientSecret,
            {
              fullName,
              email,
              tosShowAndAccepted: false,
            }
          ).then((result) => {
            handleTransactionResponse(
              result,
              () => this.handleRedirect(redirectLink),
              () => this.root.purchaseMethodsStore.stripeAuthentificationLoadingToggle(false)
            )
          })
        }

        if (isDigitalPayment) {
          handleStripeDigitalTransaction(
            submitData.stripeClient,
            submitData.expressElement,
            submitData.elements,
            clientSecret,
            redirectLink,
            () => this.toggleLoading(false)
          )
        }
      } else {
        this.handleRedirect(redirectLink)
      }
    } else {
      this.toggleLoading(false)
    }
  }

  @action submitTerms = (terms?: any) => terms

  constructor(root: ShopRootStore) {
    super()
    this.root = root
    makeObservable(this)

    this.childApi = createDealsApi(root?.apiClient ?? apiClient)
  }
}
