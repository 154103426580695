import { LOCALES } from '@elo-kit/constants/locales.constants'

import { PAYMENT_PLANS } from 'constants/pricingPlans.constants'

import VisaMasterCardLogo from 'images/payment_methods/VisaMasterCard.svg'
import AmEx from 'images/payment_methods/AmEx.svg'
import PayPalLogo from 'images/payment_methods/Paypal.svg'
import SofortLogo from 'images/payment_methods/SofortDark.svg'
import SepaLogo from 'images/payment_methods/Sepa.svg'
import P24Logo from 'images/payment_methods/P24.svg'
import PayLaterLogo from 'images/payment_methods/PayLater.svg'
import VisaMasterMaestroCardLogo from 'images/payment_methods/VisaMasterMaestroCard.svg'
import ResellerModeLogo from 'images/payment_methods/resellerMode.svg'
import SofortSepaLogo from 'images/payment_methods/Sofort+SEPA.svg'
import ApplePay from 'shared/components/purchase-methods/assets/paymethods/ApplePay.svg'
import GooglePay from 'shared/components/purchase-methods/assets/paymethods/GooglePay.svg'
import BankWireEnLogo from 'images/payment_methods/BankWireEn.svg'
import BankWireDeLogo from 'images/payment_methods/BankWireDe.svg'

export const PAYMENT_FORMS = {
  paypal: 'paypal',
  card: 'card',
  cardAmEx: 'cardAmEx',
  sofort: 'sofort',
  bankWire: 'bank_wire',
  sepa: 'sepa',
  payLater: 'pay_later',
  p24: 'p_24',
  notAssigned: 'not_assigned',
  applePay: 'apple_pay',
  googlePay: 'google_pay',
  sofortSepa: 'sofort_sepa',
  ideal: 'ideal',
  idealSepa: 'ideal_sepa',
  klarna: 'klarna',
}

export const PAYMENT_METHODS = {
  lemonWay: 'lemonWay',
  mangoPay: 'mangoPay',
  stripe: 'stripe',
  payPal: 'payPal',
  elopageConnect: 'elopageConnect',
  resellerMode: 'resellerMode',
  inkasso: 'inkasso',
}

export const PAYMENT_PROVIDERS = {
  lemonWay: 'lemonway',
  mangoPay: 'mango_pay',
  elopageConnect: 'elopage_connect',
  stripe: 'stripe',
}

export const PAYMENT_PROVIDERS_LIST = Object.values(PAYMENT_PROVIDERS)

export const KLARNA_ROOT_ID = 'klarna-bank-element'
export const STRIPE_ELEMENT_ROOT_ID = 'stripe-bank-element'
export const P24_ROOT_ID = 'p24-bank-element'
export const IDEAL_ROOT_ID = 'ideal-bank-element'

export const TWO_STEP_PAYMENT_STEPS = {
  step1: 1,
  step2: 2,
}

export const STRIPE_SOFORT_ALLOWED_COUNTRIES = ['AT', 'BE', 'DE', 'ES', 'IT', 'NL']

export const getDynamicCheckoutCtaText = () => ({
  [PAYMENT_FORMS.paypal]: I18n.t('react.shared.button.paypal_cta'),
  [PAYMENT_FORMS.sofort]: I18n.t('react.shared.button.sofort_cta'),
  [PAYMENT_FORMS.bankWire]: I18n.t('react.shared.button.bank_wire_cta'),
  [PAYMENT_FORMS.sepa]: I18n.t('react.shared.button.sepa_cta'),
  [PAYMENT_FORMS.googlePay]: I18n.t('react.shared.button.google_pay_cta'),
  [PAYMENT_FORMS.payLater]: I18n.t('react.shared.button.pay_later_cta'),
  [PAYMENT_FORMS.ideal]: I18n.t('react.shared.button.ideal_cta'),
  [PAYMENT_FORMS.p24]: I18n.t('react.shared.button.p24_cta'),
  [PAYMENT_FORMS.applePay]: I18n.t('react.shared.button.apple_pay_cta'),
  [PAYMENT_FORMS.card]: I18n.t('react.shared.button.card_cta'),
  [PAYMENT_FORMS.klarna]: I18n.t('react.shared.button.klarna_cta'),
})

export const CARD_NUMBER = '**** **** **** 1234'

export const DEFAULT_PAYMETHODS_ORDER = [
  PAYMENT_FORMS.paypal,
  PAYMENT_FORMS.card,
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.sepa,
  PAYMENT_FORMS.sofort,
  PAYMENT_FORMS.applePay,
  PAYMENT_FORMS.googlePay,
  PAYMENT_FORMS.p24,
  PAYMENT_FORMS.payLater,
  PAYMENT_FORMS.ideal,
  PAYMENT_FORMS.klarna,
]

export const PAYMENT_LOGOS_LIST_HEIGHT = {
  p_24: 24,
  sofort: 35,
  card: 18,
  ideal: 35,
  klarna: 35,
}

export const PAYMENT_SYSTEMS = {
  ...PAYMENT_PROVIDERS,
  paypal: PAYMENT_FORMS.paypal,
  directBank: 'direct_bank',
}

const BankWireLogo = I18n.locale === LOCALES.en ? BankWireEnLogo : BankWireDeLogo

export const PAYMENT_FORMS_LOGOS = {
  card: VisaMasterCardLogo,
  cardAmEx: [AmEx, VisaMasterCardLogo],
  paypal: PayPalLogo,
  [PAYMENT_FORMS.sofort]: SofortLogo,
  sepa: SepaLogo,
  bankWire: BankWireLogo,
  p24: P24Logo,
  [PAYMENT_FORMS.p24]: P24Logo,
  [PAYMENT_FORMS.bankWire]: BankWireLogo,
  [PAYMENT_FORMS.payLater]: PayLaterLogo,
  stripeCardAmEx: [AmEx, VisaMasterMaestroCardLogo],
  stripeCard: VisaMasterMaestroCardLogo,
  resellerMode: ResellerModeLogo,
  [PAYMENT_FORMS.sofortSepa]: SofortSepaLogo,
  applePay: ApplePay,
  googlePay: GooglePay,
  [PAYMENT_FORMS.applePay]: ApplePay,
  [PAYMENT_FORMS.googlePay]: GooglePay,
}

export const WRONG_VAT = 'wrong vat'

export const PAYMENT_METHODS_WITHOUT_KLARNA = [
  PAYMENT_FORMS.paypal,
  PAYMENT_FORMS.card,
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.sepa,
  PAYMENT_FORMS.sofort,
  PAYMENT_FORMS.applePay,
  PAYMENT_FORMS.googlePay,
  PAYMENT_FORMS.p24,
  PAYMENT_FORMS.payLater,
  PAYMENT_FORMS.ideal,
]

export const PAYMENT_METHOD_BY_PRICING_PLAN_KLARNA_SUBSCRIPTIONS = {
  [PAYMENT_PLANS.oneTime]: [...PAYMENT_METHODS_WITHOUT_KLARNA, PAYMENT_FORMS.klarna],
  [PAYMENT_PLANS.subscription]: [...PAYMENT_METHODS_WITHOUT_KLARNA, PAYMENT_FORMS.klarna],
  [PAYMENT_PLANS.installment]: PAYMENT_METHODS_WITHOUT_KLARNA,
  [PAYMENT_PLANS.limitedSubscription]: [...PAYMENT_METHODS_WITHOUT_KLARNA, PAYMENT_FORMS.klarna],
}

export const PAYMENT_METHOD_BY_PRICING_PLAN = {
  [PAYMENT_PLANS.oneTime]: [...PAYMENT_METHODS_WITHOUT_KLARNA, PAYMENT_FORMS.klarna],
  [PAYMENT_PLANS.subscription]: PAYMENT_METHODS_WITHOUT_KLARNA,
  [PAYMENT_PLANS.installment]: PAYMENT_METHODS_WITHOUT_KLARNA,
  [PAYMENT_PLANS.limitedSubscription]: PAYMENT_METHODS_WITHOUT_KLARNA,
}

/** paypal */
export const PAYPAL_PROVIDERS: {
  rest: 'rest'
  nvp: 'nvp'
} = {
  rest: 'rest',
  nvp: 'nvp',
}

export const COUNTRIES_LIST_IBAN = [
  { value: 'DE', label: 'Germany' },
  { value: 'FR', label: 'France' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'ES', label: 'Spain' },
  { value: 'IE', label: 'Ireland' },
]

export const LEMONWAY_EXTERNAL_STATUSES = {
  kyc1: 'kyc_1',
  kyc2: 'kyc_2',
  closed: 'closed',
  kycIncomplete: 'kyc_incomplete',
  rejected: 'rejected',
  blocked: 'blocked',
  expired: 'expired',
}
export const LEMONWAY_INTERNAL_STATUSES = {
  change: 'change',
  inReview: 'in_review',
  reviewed: 'reviewed',
  init: 'init',
  requested: 'requested',
}

export const LEMONWAY_LEGITIMATION_DOCUMENTS_STATUSES = {
  accepted: 'accepted',
  expired: 'expired',
  notAccepted: 'not_accepted',
  notVerified: 'not_verified',
  onHold: 'on_hold',
  sent: 'sent',
  unreadable: 'unreadable',
  wrongName: 'wrong_name',
  wrongType: 'wrong_type',
}

export const MANGOPAY_EXTERNAL_STATUSES = {
  light: 'light',
  regular: 'regular',
}

export const MANGOPAY_INTERNAL_STATUSES = {
  headQuarter: 'head_quarter',
  legalRepresentative: 'legal_representative',
  documents: 'documents',
  ubo: 'ubo',
  requested: 'requested',
  inReview: 'in_review',
  change: 'change',
  registered: 'registered',
  legitimated: 'legitimated',
  rejected: 'rejected',
}

export const MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES = {
  created: 'created',
  init: 'init',
  refused: 'refused',
  validated: 'validated',
  validationAsked: 'validation_asked',
}

export const UBO_DECLARATION_STATUSES = {
  ...MANGOPAY_LEGITIMATION_DOCUMENTS_STATUSES,
  incomplete: 'incomplete',
}

export const BANK_ACCOUNT_LEGITIMATION_TYPES = {
  lemonWay: 'LemonWayBankAccount',
  mangoPay: 'MangoPayBankAccount',
  elopageConnect: 'ElopageConnectBankAccount',
  inkasso: 'ElopageBankAccount',
}

export const LEGITIMATION_TYPES = {
  lemonWay: 'LemonWayLegitimation',
  mangoPay: 'MangoPayLegitimation',
  elopageConnect: 'ElopageConnectLegitimation',
  inkasso: 'InkassoBankAccount',
}

export const ELOPAGE_CONNECT_LEGITIMATION_EXTERNAL_STATUSES = {
  noDocuments: 'no_documents',
  verification: 'verification',
  rejected: 'rejected',
  payments: 'payments',
  payouts: 'payouts',
  paymentsPayouts: 'payments_payouts',
}

export const ELOPAGE_CONNECT_INTERNAL_STATUTES = {
  requested: 'requested',
  dataMismatch: 'data_mismatch',
}

export const PAYMENT_PROVIDER_IDS = {
  all: 0,
  lemonWay: 1,
  paypal: 2,
  stripe: 3,
  mangoPay: 4,
  elopageConnect: 5,
}

export const PAYER_FORM_TYPE = {
  private: 'private',
  business: 'business',
  private250: 'private250',
}
